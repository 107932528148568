import React from "react";
import "./SignUp.css";
import axios from "axios";

import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import constant from "../../../Utility/Constants/constant";
import dataStored from "../../../Utility/DataStoring/dataStored";

const urls = require("../../../Utility/URL/url.js");

function SignUp() {
  const location = useLocation();
  const [signUpInformation, setSignUpInformation] = useState({
    firstName: "",
    lastName: "",
    email: location.state?.email || "",
    password: "",
    repeatPassword: "",
    role: "",
  });
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  function handleInput(event) {
    const { name, value, type } = event.currentTarget;

    if (type === "checkbox") {
      setSignUpInformation({
        ...signUpInformation,
        [name]: event.currentTarget.checked,
      });
    } else {
      setSignUpInformation({ ...signUpInformation, [name]: value });
    }
  }

  function handleSubmission(event) {
    event.preventDefault();

    // first run the otp validation API

    // OTP validation component call

    // configuring the json cause it contains the checked value which we don't need right now;
    // not including the checked value right now.

    const { firstName, lastName, email, password, role } = signUpInformation;

    //First need to check all the input fields are entered or not
    let errors = validateInput();

    if (isValid(errors)) {
      // we can execute here any other component or stuff if we want to all the input fields
      // are entred and correct.

      let json = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        password: password,
        role: role ? ["advisor"] : [""],
      };

      // otp -> mail pr
      axios
        .post(urls.POST_api_auth_signup, json)
        .then((res) => {
          if (res.status === 200) {
            //user regeistered successfully
            // we are getting back the user data from
            // the database by calling signing in.

            axios
              .post(urls.POST_api_auth_signin, {
                email: email,
                password: password,
              })
              .then((res) => {
                // we get the userData here so we are just directly getting
                // to the validator page for email verification.

                const key = constant.ABD_SESSION;
                const value = res.data;

                // const tokenType = res.data.tokenType;
                // const accessToken = res.data.accessToken;
                // const refreshToken = res.data.refreshToken;
                // const userName = res.data.username;

                dataStored(key, value);

                navigate("/validator", {
                  state: { email: signUpInformation.email, userData: res.data },
                });
              });
          }
        })
        .catch((err) =>
          toast.error(`${err.response.data.message}`, {
            position: toast.POSITION.TOP_RIGHT,
          })
        );
    } else {
      setErrors(errors);
    }
  }
  function validateInput() {
    let { firstName, lastName, email, password, repeatPassword } =
      signUpInformation;
    let errors = {};

    if (!firstName) errors.firstName = "First Name is mandatory";
    if (!lastName) errors.lastName = "Last Name is mandatory";
    if (!email) errors.email = "Email is mandatory";
    if (!password)
      errors.password =
        "Password cannot be empty,For your privacy it should be entered";
    if (!repeatPassword) errors.repeatPassword = "Repeat password is mandatory";

    return errors;
  }

  function isValid(errors) {
    // errors would have keys with non empty strings as values.
    let keys = Object.keys(errors); //keys in an array.
    let count = keys.reduce((acc, curr) => (errors[curr] ? acc + 1 : acc), 0);
    return count === 0;
  }

  return (
    <React.Fragment>
      <section className="vh-100 main-container bgcwhitecustom">
        <div className="container py-4 h-100">
          <div className="row d-flex align-items-center justify-content-center h-100">
            <div className="col-md-8 col-lg-7 col-xl-6 width48pccustom">
              <img
                src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.svg"
                className="img-fluid"
                alt="loading..."
              />
            </div>
            <div className="col-md-7 col-lg-5 col-xl-5 offset-xl-1 bordercustomround">
              <form className="m-2">
                <div className="row">
                  {/*firstname */}
                  <div className="col-md-6 mb-4 pb-2">
                    <div className="form-outline">
                      <label className="form-label" htmlFor="firstName">
                        First Name
                      </label>
                      <input
                        placeholder="Aman"
                        type="text"
                        id="firstName"
                        name="firstName"
                        value={signUpInformation.firstName}
                        className="form-control form-control-lg"
                        onChange={handleInput}
                      />
                      {errors.firstName ? (
                        <span className="text-danger">{errors.firstName}</span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div className="col-md-6 mb-4 pb-2">
                    <div className="form-outline">
                      <label className="form-label" htmlFor="lastName">
                        Last Name
                      </label>
                      <input
                        placeholder="Goel"
                        type="text"
                        id="lastName"
                        name="lastName"
                        value={signUpInformation.lastName}
                        className="form-control form-control-lg"
                        onChange={handleInput}
                      />
                      {errors.lastName ? (
                        <span className="text-danger">{errors.lastName}</span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>

                <div className="form-outline mb-4">
                  <label className="form-label">Email address</label>
                  <input
                    placeholder="someone@example.com"
                    type="email"
                    id="email"
                    name="email"
                    value={signUpInformation.email}
                    className="form-control form-control-lg"
                    onChange={handleInput}
                  />
                  {errors.email ? (
                    <span className="text-danger">{errors.email}</span>
                  ) : (
                    ""
                  )}
                </div>

                <div className="form-outline mb-4">
                  <label className="form-label">Password</label>
                  <input
                    placeholder="••••••••"
                    type="password"
                    id="password"
                    name="password"
                    value={signUpInformation.password}
                    className="form-control form-control-lg"
                    onChange={handleInput}
                  />
                  {errors.password ? (
                    <span className="text-danger">{errors.password}</span>
                  ) : (
                    ""
                  )}
                </div>

                <div className="form-outline mb-4">
                  <label className="form-label">Re-Enter Your Password</label>
                  <input
                    placeholder="••••••••"
                    type="password"
                    id="Re-Enter"
                    name="repeatPassword"
                    value={signUpInformation.repeatPassword}
                    className="form-control form-control-lg"
                    onChange={handleInput}
                  />
                  {errors.repeatPassword ? (
                    <span className="text-danger">{errors.repeatPassword}</span>
                  ) : (
                    ""
                  )}
                </div>

                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="role"
                    checked={signUpInformation.role}
                    onChange={handleInput}
                  />
                  <label className="form-check-label">
                    To serve as an advisor, kindly mark this checkbox.
                  </label>
                </div>

                <div className="d-flex justify-content-center">
                  <button
                    type="submit"
                    className="btn btn-primary btn-lg btn-block martop1remcustom px-25custom"
                    onClick={handleSubmission}
                  >
                    Sign up
                  </button>
                </div>
                <ToastContainer />
              </form>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default SignUp;
