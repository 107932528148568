import React, { useEffect, useState } from 'react';
import './header.css'
import Categories from "../mainPage/Categories";
import { Link } from "react-router-dom";

const HeaderSection = () => {

  const [activeCategory, setActiveCategory] = useState(null);
  const [activeCategoryMob, setActiveCategoryMob] = useState(null);


  useEffect(() => {
    document.getElementById("mySidenav").style.width = "0";
    setActiveCategory(null);
    setActiveCategoryMob(null);
  }, [])

  function toggleSideNav() {
    setActiveCategoryMob(null);
    document.getElementById("mySidenav").style.width = "100%";
  }

  function closeSideNav() {
    setActiveCategoryMob(null);
    document.getElementById("mySidenav").style.width = "0";
  }



  const toggleCategory = (category) => {
    setActiveCategoryMob(activeCategoryMob === category ? null : category);
  };

  const handleMouseEnter = (category) => {
    setActiveCategory(category);  // Set the active category on hover
  };

  const handleMouseLeave = () => {
    setActiveCategory(null);  // Reset the active category when not hovering
  };
  return (
    <div className="header-block">
      <div className="header-wrapper flex justify-content-between">
        <a variant="base" className="header-logo" href="/">
          <div className="logo-wrapper" data-testid="logo" width="auto">
            Advising Buddy
          </div>
        </a>
        <ul className="item-list">
          <li className="category-item">
            <div className="dropdown">
              <span id="header-menu-item-0" className="dropdown-toggle">Categories</span>
              <div className="dropdown-menu" onMouseLeave={handleMouseLeave}>
                {/* Left Column for Categories */}
                <div className={`dropdown-column-left ${activeCategory ? '' : 'w-100 min-w-300 '}`}>
                  {Object.keys(Categories).map((category, index) => (
                    <div
                      key={index}
                      className="dropdown-item"
                      onMouseEnter={() => handleMouseEnter(category)}  // Set category on hover
                    >
                      <span>{category.replace(/([A-Z])/g, ' $1').trim()}</span> &nbsp;{(activeCategory === category) && <i className={`fas fa-chevron-right`}></i>}
                    </div>  
                  ))}
                </div>

                {/* Right Column for Subcategories */}
                {activeCategory && (
                  <div className={`dropdown-column-right ${activeCategory ? 'show' : ''}`}>
                    <div>
                      {Categories[activeCategory].map((json, idx) => (
                        <div key={idx} className="dropdown-link">
                          <Link to={`/${json.username}`} className="dropdown-item">
                            {json.name}
                          </Link>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </li>
          <li className='search-bar-wrapper'>
            <div className='search-bar'>
              <input type='text' placeholder='Search' />
              <div className='search-button'><i className="fa fa-search text-white"></i></div>
            </div>
          </li>
        </ul>
        <ul className='auth-items'>
          <li className="skip">
            <a className="login-button" data-testid="login-header" href="/signin">
              <span className="">Log In</span></a>
          </li>
          <li className="skip">
            <a data-testid="signup-button" href="/signup">
              <span className="signup-button">Sign Up</span></a>
          </li>
        </ul>
        <div className='search-bar-wrapper d-lg-none'>
          <div className='search-bar'>
            <div className='search-button'><i className="fa fa-search "></i></div>
          </div>
          <div className="menu-icon" onClick={toggleSideNav}>
            <div className='border border-black'></div>
            <div className='border border-black'></div>
            <div className='border border-black'></div>
          </div>
        </div>
        <div className="sideBar" id='mySidenav'>
          <div className='sideNavBarBlock'>
            <div className='d-flex justify-content-between align-items-center px-3 shadow-sm mb-3'>
              <div className="logo-wrapper font-weight-bold" data-testid="logo" width="auto">
                Advising Buddy
              </div>
              <span className="closebtn" onClick={closeSideNav}>&times;</span>
            </div>
            <ul className="item-list-mob">
              <li className="category-item w-100" >
                <div id="category-menu">
                  <span className="d-flex justify-content-between" id="header-menu-item-0" onClick={() => toggleCategory('category')}>
                    Categories
                    <i className={`fas fa-chevron-${activeCategoryMob ? 'up' : 'down'}`}></i>
                  </span>

                  <ul className={`collapse collapse-content ${activeCategoryMob ? 'show' : ''}`} id="category">
                    {Object.keys(Categories).map((category, index) => (
                      <li key={index}>
                        <span onClick={() => toggleCategory(category)}>
                          {category} &nbsp;
                          <i className={`fas fa-chevron-${activeCategoryMob === category ? 'up' : 'down'}`}></i>
                        </span>

                        <ul className={`collapse collapse-content ${activeCategoryMob === category ? 'show' : ''}`} id={category}>
                          {Categories[category].map((json, idx) => (
                            <li key={idx}>
                              <Link
                                to={`/${json.username}`}
                                className=""
                                href="#"
                              >
                                {json.name}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </li>
                    ))}
                  </ul>
                </div>
              </li>
            </ul>
            <ul className='auth-items-mob'>
              <li className="skip w-100">
                <a className="login-button" data-testid="login-header" href="/signin">
                  <span className="">Log In</span></a>
              </li>
              <li className="skip w-100">
                <a data-testid="signup-button" href="/signup">
                  <span className="signup-button">Sign Up</span></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeaderSection;